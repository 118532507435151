.borderGap {
  border-bottom: 1px dashed #e5eaee;
  margin-bottom: 20px;
  width: 100%;
}

.site-name {
  color: #fff;
  margin-top: 10px;
  margin-left: 10px;
  font-weight: 500;
  font-size: 15px;
}

@media (min-width: 992px) {
  .header-fixed.subheader-fixed.subheader-enabled .wrapper {
    padding-top: 70px;
  }
}

@media (max-width: 991.98px) {
  .subheader-enabled .content {
    padding-top: 15px;
  }
}

.aside-minimize .brand .brand-logo span {
  display: none;
}

.site_name_login {
  text-transform: uppercase;
  font-weight: 800;
  color: #2f3044;
  margin-top: -5px !important;
}

.login-label {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #000000 !important;
}

/* Design Dash Board */

.shipping-card {
  background-color: #4a7dff;
  padding-top: 30px;
  padding-bottom: 5px !important;
}

.shipping-card h2 {
  color: white;
  font-weight: 800;
  padding-top: 15px;
}

.second-color {
  background-color: #3699ff;
}

.shipping-card p {
  color: white;
  font-size: 12px;
}

.spacer {
  padding-left: 30px;
}

/* Design Dash Board */

/* custom form design */

.formHeight {
  height: 30px !important;
  background: #fff;
  font-size: 10px !important;
  font-weight: 400;
}

.css-1fhf3k1-control {
  height: 30px !important;
  min-height: 30px !important;
}

.form-input-white {
  background: #fff;
}

.formFont {
  font-size: 10px !important;
  margin-bottom: 0px !important;
}

button.saveButton {
  height: 29px;
  line-height: 10px;
  background-color: #789dfe !important;
  font-weight: 400;
  border-radius: 3px;
}

.cancelButton {
  background-color: #e5eaee !important;
  /* height: 29px; */
  line-height: 12px !important;
  color: #464e5f !important;
  font-weight: 400;
  border-radius: 3px;
}

.formSelect {
  height: 30px;
  font-size: 10px !important;
  background: #f3f6f9;
  font-weight: 500;
  padding-top: 5px;
}

.chooseFileText {
  color: #b5b5c3;
}

.fileInput {
  border-bottom-right-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

input.fileInput,
span#inputGroupPrepend {
  height: 30px;
}

/* DashBoard Main Page Design Starts */

.dashboard__pb {
  padding-bottom: 30px;
}

.paid__widget {
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 10px;
  background: #f64e60;
  overflow: hidden;
  color: #ffff;
  padding: 20px;
  overflow: hidden;
}

.widget__right {
  padding-top: 10px;
}

.widget__right p {
  color: #ffff;
  font-weight: 500;
  font-size: 13px !important;
  padding-top: 7px;
}

.widget__right h4 {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

.chart__box {
  box-shadow: 0px 2px 6px #2c28281c;
  border-radius: 10px;
  padding-bottom: 10%;
  overflow: hidden;
  height: 380px;
}

.chart__box p {
  padding-top: 10px;
  font-size: 11px !important;
  padding-left: 10px;
}

.chart__box h4 {
  font-size: 14px !important;
  padding-left: 10px;
  color: #172b4d;
}

.paid__widget.one {
  background-color: #6993ff;
}

.paid__widget.two {
  background-color: #1bc5bd;
}

.paid__widget.three {
  background-color: #ffa800;
}

.dough__main {
  padding-top: 30px;
}

.doug__left {
  float: left;
  width: 52%;
  float: left;
  width: 52%;
  padding-top: 25px;
}

.doug__right {
  float: left;
  width: 40%;
  padding-top: 25px;
}

.color__circle {
  display: inline-block;
}

.stat__1 {
  position: relative;
}

.stat__1::before {
  content: "";
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: black;
  position: absolute;
  top: 10px;
  left: 0px;
  border: 3px solid #feb969;
  content: "";
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 9px;
  left: -4px;
  border: 3px solid #feb969;
}

.border__orange {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: black;
  position: absolute;
  top: 10px;
  left: 0px;
  border: 3px solid #2dce98;
}

.border__blue {
  border: 3px solid #1f50b1;
}

.border__red {
  border: 3px solid #f53c56;
}

.dough__list {
  color: #4d4f5c;
  font-size: 11px;
  font-weight: 600;
  margin-left: 3px;
  font-family: "poppins";
}

.list__1 {
  margin-right: 25px;
}

.list__2 {
  margin-right: 15px;
}

.list__3 {
  margin-right: 70px;
}

.list__4 {
  margin-right: 67px;
}

.dough__num {
  font-weight: 500;
}

.widget__left img {
  width: 30px;
}

.widget__left.three img {
  width: 20px;
}

/* DashBoard Main Page Design Ends */

/* login page  */

.form-control:focus {
  color: #000000 !important;
}

.stat__2 {
  position: relative;
}

.stat__2::before {
  content: "";
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 9px;
  left: -4px;
  border: 3px solid #2dce98;
}

.stat__3 {
  position: relative;
}

.stat__3::before {
  content: "";
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 9px;
  left: -4px;
  border: 3px solid #1f50b1;
}

.stat__4 {
  position: relative;
}

.stat__4::before {
  content: "";
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 9px;
  left: -4px;
  border: 3px solid #f53c56;
}

a#kt_login_forgot {
  color: #3699ff !important;
  font-weight: 600;
}

button#kt_login_signin_submit {
  box-shadow: #00000029 !important;
  margin: auto;
}

.heading {
  color: #160637;
  font-size: 30px !important;
}

span.loginText {
  font-weight: 600;
}

a {
  color: #4174aa !important;
  font-size: 12px;
}

img.loginpageLogo {
  width: 190px;
  height: 165px;
}

.login.login-1 .login-aside {
  height: 100vh;
}

.loginForm input.form-control {
  background: #eef1f5 !important;
  height: 36px !important;
  border: 1px solid #eef1f5;
  box-shadow: 0px 1px 5px #00000010 !important;
}

h1.font-size-h1.heading {
  text-align: center;
  font-weight: 500;
  /* margin-bottom: 20px; */
}

.heading {
  font-size: 22px !important;
}

.overlay__caption {
  background-color: #0062ff89 !important;
  color: white;
  border-radius: 9px;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.2s;
  position: relative;
  border-radius: 5px;
}

.overlay__caption:hover {
  color: #fff;
  /* box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15); */
}

.overlay__caption:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #007bff;
  opacity: 0;
  transition: all 0.3s, opacity 0.2s;
  transform: scaleY(0);
  transform-origin: bottom;
}

.overlay__caption:hover:before {
  transform: scaleY(1);
  opacity: 0.25;
}

.login.login-1 .login-aside {
  width: 100%;
}

.loginForm input.form-control {
  background: #eef1f5 !important;
  height: 36px !important;
  border: 1px solid #95989a4d;
  box-shadow: 0px 1px 5px #00000029 !important;
}

.loginForm input#formBasicChecbox {
  box-shadow: 0px 3px 5px #00000021 !important;
  border: none !important;
}

.flex-row-fluid.d-flex.flex-column.position-relative.loginPage {
  max-width: 50% !important;
}

.login-aside.d-flex.flex-row-auto.loginBg {
  max-width: 50%;
}

input.form-control.loginFormInput.emailinput.h-auto.py-5.px-6.is-valid {
  width: 300px !important;
}

.login-signin {
  width: 400px;
}

img.loginpageLogo {
  margin: auto;
  display: block;
}

/* Table */

button.btn.approve {
  background: #eee5ff;
  color: #8950fc;
  border-color: #eee5ff;
  font-weight: 500;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2;
}

i.editIcon {
  background: #f3f6f9;
  padding: 8px;
  border-radius: 5px;
  color: #3699ff;
}

/* i.editIcon:hover {
    border: 2px solid #8950fc;
    padding: 8px;
  } */

input.form-check-input.position-static {
  background: #f3f6f9;
}

.circulerBar {
  width: 39px;
}

.voyageTable td {
  color: #464e5f;
  font-size: 13px;
}

.voyageTable th {
  color: #b5b5c3;
  font-size: 12px;
  text-transform: uppercase;
}

h1.tableheading {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  margin-top: 5px;
}

i.tableFilter {
  color: #3699ff;
}

.input-area-add .float-left {
  width: 80%;
}

.input-area-add .float-right {
  width: 20%;
}

.input-area-add input,
.input-area-add select {
  border-radius: 0px !important;
}

.btn-modal-close {
  position: absolute;
  right: -16px;
  padding-right: 20px;
  cursor: pointer;
  top: -10px;
  background: #ffffffed;
  border-radius: 20px;
  width: 36px;
  height: 36px;
  padding: 12px;
  box-shadow: 1px 2px 30px grey;
}

.btn-default {
  position: relative !important;
}

.btn-default i {
  width: 9px;
  height: 9px;
  position: absolute;
  right: 10px;
  bottom: 7px;
  font-size: 14px !important;
}

button.btn.btn-default {
  border: 1px solid #e5eaee;
}

.btn.btn-default i {
  color: #d9e0e6;
}

.btn-modal-close i {
  margin: -1px;
  margin-left: 1px;
  margin-top: -2px;
}

.input-area-add .btn-default,
.input-area-add .btn-default:hover {
  border-radius: 4px !important;
  /* background: white; */
  /* border-color: #ecf0f3; */
  /* border-left: 0px !important; */
  margin-left: -3px;
  background: #fff;
  border: 1px solid #e5eaee !important;
  height: 30px;
  background: #fff !important;
}



.tbl-survey {
  /* width: 74.5% !important; */
  width: 100% !important;
  margin: 10px 0px 10px 0px;
}

.tbl-survey thead {
  background: red !important;
}

.tbl-survey th {
  background: #789dfe38;
}

/* basic form */

.css-1okebmr-indicatorSeparator {
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}

.css-yk16xz-control {
  background: #fff !important;
  height: 30px !important;
  min-height: 30px !important;
  border: 1px solid #e5eaee !important;
}

.css-1pahdxg-control {
  background: #fff !important;
  height: 30px !important;
  min-height: 30px !important;
  border: 1px solid #e5eaee !important;
}

.css-g1d714-ValueContainer {
  padding: 3px !important;
  /* margin-top: -5px !important; */
}

.aside-menu .menu-nav>.menu-section {
  margin: 0px 0 0 0 !important;
  height: 10px !important;
}

.aside-menu .menu-nav>.menu-item>.menu-link {
  padding: 5px 20px !important;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link {
  min-height: 30px !important;
}

.css-26l3qy-menu {
  padding: 0px !important;
}

.aside-menu .menu-nav>.menu-item>.menu-heading,
.aside-menu .menu-nav>.menu-item>.menu-link {
  min-height: 36px !important;
}

.aside .aside-menu .menu-nav {
  width: auto !important;
  /* width: 1800px; */
}

.css-1laa021-a11yText {
  padding: 0px !important;
}

.css-1wa3eu0-placeholder {
  top: 42% !important;
  font-size: 10px !important;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  margin-top: -5px !important;
}

.css-1uccc91-singleValue {
  top: 35% !important;
  font-size: 10px !important;
}

.searchInput {
  background: #fff !important;
  height: 30px !important;
  border: 1px solid #e5eaee !important;
  box-shadow: none !important;
}

.searchInput i.flaticon-search {
  padding-left: 5px !important;
}

button.MuiButtonBase-root.MuiIconButton-root.searchPlaceholder {
  padding: 0px !important;
  padding-top: 2px !important;
}

input.MuiInputBase-input {
  margin-left: 10px;
}

.table.table-head-custom thead th,
.table.table-head-custom thead tr {
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  border-bottom: 1px solid #ecf0f3 !important;
  padding: 4px;
}

.table.table-head-custom tbody th,
.table.table-head-custom tbody tr {
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  border-bottom: 1px solid #ecf0f3 !important;
  padding: 8px;
}

.user-list-table td,
th {
  border-bottom: 1px solid #ecf0f3 !important;
}

.table {
  margin-bottom: 2px !important;
}

.table.table-head-custom thead td {
  color: #464e5f;
  font-weight: 400 !important;
}

.table.table-head-custom thead th {
  color: #b5b5c3;
  font-weight: 800;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.modal-body {
  padding: 0.75rem;
  padding-top: 0px !important;
}

input[type="file"] {
  border: 1px solid #e5eaee;
  border-radius: 4px;
}

.MuiPaper-rounded {
  border-radius: 6px !important;
}

.custome-datepicker {
  position: relative;
}

i.fas.fa-calendar-alt {
  position: absolute;
  top: 29px;
  right: 20px !important;
  font-size: 10px !important;
}

.page-item.active .page-link {
  background-color: #3699ff;
  border-color: #3699ff;
  color: #fff !important;
  z-index: 0;
  position: absolute;
  color: #abb5be;
  /* left: 249px; */
}

input.date-picker,
input.react-datepicker-ignore-onclickoutside {
  width: 100% !important;
  border-radius: 4px;
  height: 30px;
  border: 1px solid #e5eaee;
  font-size: 10px !important;
  color: #464e5f;
  padding-left: 10px;
  position: relative !important;
}

.loginBg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

button#kt_login_signin_submit {
  width: 142px;
}

/* line chart */

.line-chartsection {
  border-radius: 12px;
}

.pointer {
  cursor: pointer;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.status-list {
  background: aliceblue;
  padding: 4px;
  margin-top: 30px;
}

.float-left.total {
  width: 21%;
}

.float-left.balance {
  width: 16%;
}

/* table form */

.row.mb-5.table-form {
  align-items: center;
}

/* dashboard table */

h1.sales-payment-heading {
  font-size: 20px;
  color: #212121;
}

table.table.table.dashboard-table th,
td {
  font-weight: 600;
  color: #212121 !important;
  text-transform: capitalize;
}

p.dashboard-table-text {
  font-weight: 600;
  background-color: #f3f6f9;
}

.btn.invoice-btn {
  border-color: #789dfe;
  background: #789dfe;
}

.btn.invoice-btn:hover {
  border-color: #789dfe !important;
  background: #789dfe !important;
}

button.btn.text-white.float-right.invoice-btn {
  padding: 5px 10px !important;
  border-radius: 4px !important;
}

p.show-value-text {
  color: #000000 !important;
}

.attachment-file input {
  /* color: #b5b5c3; */
  font-size: 12px !important;
}

::-webkit-file-upload-button {
  background: #fff;
  border: 1px solid #e5eaee;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px !important;
  padding: 5px;
  /* color: #b5b5c3; */
}

/*  */

a._2pGos._3CDiP._2RWmX,
a._2pGos._3CDiP {
  background: #e5e5e5 !important;
  line-height: 10px !important;
  color: #464e5f !important;
  font-weight: 400;
  border-radius: 3px !important;
}

._hsN1w {
  line-height: 10px !important;
  background-color: #789dfe !important;
  border: 1px solid #789dfe !important;
  font-weight: 400;
  border-radius: 3px !important;
  color: #fff !important;
}

._3uApM {
  margin-top: -5px !important;
  margin-left: 25px;
}

h3 {
  font-size: 18px !important;
}

._1hzhf {
  font-size: 14px;
}

._2Jtxm._35Ago ._2kL0S {
  margin-left: -140px;
}

._2Jtxm {
  margin-left: -66px;
}

._1Lo2h {
  width: 109%;
}

/* Certificate create */

.card-header.certificate-cardheader {
  min-height: 0px !important;
}

.certificate-card-body {
  padding: 0rem 2.25rem !important;
}

.header.header-fixed {
  height: 50px !important;
}

.header-fixed.subheader-fixed.subheader-enabled .wrapper {
  padding-top: 54px !important;
}

.custome-addnew-btn {
  height: 25px;
  padding: 5px;
}

/* item list start */

i.item-list-icon {
  font-size: 12px !important;
}

.itemtable td {
  font-weight: 400 !important;
}

i.editIcon.item-list-icon {
  padding: 5px;
  margin-left: 5px;
}

button.item-list-btn {
  height: 33px;
  border-radius: 4px;
  background: #789dfe !important;
  border: 1px solid #789dfe !important;
}

/* item add */

button.item-add-btn {
  border: 1px solid #afafaf !important;
  background: #e4ebff !important;
}

button.item-add-save {
  padding-left: 35px;
  padding-right: 35px;
  color: #fff;
  background: #1bc5bd !important;
  border: #1bc5bd !important;
  border-radius: 4px !important;
}

button.item-go {
  padding-left: 20px;
  padding-right: 20px;
  background: #010808 !important;
  border: #1bc5bd !important;
  border-radius: 0px !important;
}

button.item-attach {
  padding-left: 20px;
  padding-right: 20px;
  background: #0e9dd6 !important;
  border: #1bc5bd !important;
  border-radius: 0px !important;
}

button.item-add {
  padding-left: 30px;
  padding-right: 28px;
  background: #08ae50 !important;
  border: #1bc5bd !important;
  border-radius: 0px !important;
}

table.table.table.item-add-table th,
tr,
td {
  border-top: none !important;
  border-bottom: none !important;
  font-weight: 400 !important;
}

/* booking entry */

.booking-entry-input.form-group {
  margin-left: -13px;
  width: 112% !important;
}

.input-group {
  width: 112% !important;
}

.booking-btn {
  background-color: #007bff !important;
  line-height: 12px !important;
  border-radius: 3px;
}

.MuiPaper-elevation1 {
  box-shadow: none 0px !important;
}

.booking-list-btn {
  background: #fff2d8 !important;
  border: 1px solid #fff2d8 !important;
}

.form-group label {
  font-size: 12px !important;
  margin-bottom: 0px !important;
}

span#inputGroupPrepend {
  height: 30px !important;
  border-left: 0px !important;
}

.MuiPaper-root.searchInput.MuiPaper-elevation1.MuiPaper-rounded {
  box-shadow: none !important;
}

.certificate-add-btn.btn-sm {
  line-height: 1.15 !important;
}

.row.custom-modal {
  width: 1063px;
}

hr.hr-margin {
  margin-top: 8px !important;
}

/* userlist modal */

button.modal-button {
  border: none !important;
  background: none !important;
}

/* cerificate list */

button.certificate-done {
  background: #8ec7ff !important;
  border: #8ec7ff !important;
}

.certificate-due {
  background: #678db2 !important;
  border: #678db2 !important;
}

.certificate-lis-btn {
  padding: 3px 15px !important;
  border-radius: 2px !important;
  width: 75px !important;
  border: none !important;
}

.certificate-edit-icon {
  background: #e7f3fe !important;
  padding: 5px;
  border-radius: 2px;
  color: #3699ff;
  font-size: 11px;
}

.due-days h6 {
  font-size: 11px !important;
  text-transform: uppercase;
  position: absolute;
  top: 6px;
  left: 29px;
}

.due-days {
  position: relative;
}

p.certificate-list-text {
  width: 101px;
  font-size: 12px !important;
  font-weight: bold;
}

.certificate-due-0 {
  background: #ea673e !important;
}

.certificate-due-l-30 {
  background: #8ec7ff !important;
}

.certificate-due-30-60 {
  background: #678db2 !important;
}

.certificate-due-g-60 {
  background: #8af2c0 !important;
}

/* expense addition-deduction */

/* .MuiPaper-root.makeStyles-root-1.MuiPaper-elevation1.MuiPaper-rounded {
  
    box-shadow: none !important;
    border: 1px solid #ccc !important;
} */

/* certificate color picker */

input.color-picker {
  /* margin-bottom: 7px; */
  height: 24px;
  width: 24px;
  border-radius: 3px;
  /* margin-left: 12px; */
}

.card-top-border {
  border-top: 3px solid #3699ff !important;
  border-radius: 0px !important;
}

.card-top-border-success {
  border-top: 3px solid #1bc5bd !important;
  border-radius: 0px !important;
}

.card-top-border-info {
  border-top: 3px solid #3699ff !important;
  border-radius: 0px !important;
}

.card-top-border-error {
  border-top: 3px solid #ff9da7 !important;
  border-radius: 0px !important;
}

.MuiInputBase-root.custome-purchase-search {
  width: 85%;
}

button.searchPlaceholder.purchaseSearch {
  right: 21px !important;
}

.purchaseSearch {
  margin-left: 20px !important;
}

.custome-border-left {
  min-width: 14%;
}

.custome-border-design {
  width: 100%;
  border-bottom: 2px solid lightgray;
  margin-left: 13px;
  margin-bottom: 10px;
}

/* navbar */

.aside-enabled.subheader-fixed .subheader {
  left: 255px;
}

.header-fixed.subheader-fixed .subheader {
  position: fixed;
  height: 54px;
  top: 50px !important;
  left: 0;
  right: 0;
  transition: top 0.3s ease;
  z-index: 95;
  box-shadow: 0 10px 30px 0 rgb(82 63 105 / 8%);
  background-color: #fff;
  border-top: 1px solid #ebedf3;
  margin: 0;
}

.subheader {
  display: flex;
  align-items: center;
}

.dashboard-cardsection {
  padding-top: 43px !important;
}

.dashboard-navbar-left {
  width: 50% !important;
  float: left !important;
}

.dashboard-navbar-right {
  float: right !important;
  width: 47% !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.card.card-custom>.card-header {
  min-height: 45px !important;
}

.card.card-custom>.card-body {
  padding: 0.5rem 1rem !important;
}


/* .aside {
   width: 230px !important; 
} */

.header-fixed.subheader-fixed .header {
  background: #1bbef4;
  /* background: #1e1e2d; */
}

.top-search-bar {
  background: #0000001a;
}

.top-search-bar:hover {
  background: #007bff !important;
}

@media (min-width: 992px) {
  .aside-minimize:not(.aside-minimize-hover) .brand {
    display: none !important;
  }
}

input.bulk-file-input {
  display: block;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
  border: 1px solid #ccc !important;
}

.searchPlaceholder:hover {
  background: none !important;
}

.MuiIconButton-root:hover {
  background-color: transparent !important;
}

i.flaticon-search {
  margin-bottom: 0px !important;
}

.supplier-search {
  position: relative !important;
}

button.searchPlaceholder {
  position: absolute !important;
  right: 8px !important;
}

.viewIcon {
  background: #e7f3fe;
  padding: 5px;
  border-radius: 5px;
  color: #3699ff;
  font-size: 10px;
}

.custom-border {
  border-bottom: 2px solid #ecf0f3 !important;
  width: 107%;
  margin-left: -47px;
}

.supplier-table td {
  padding: 5px 0px !important;
}

i.fas.fa-trash-alt.viewIcon {
  margin-left: 15px;
}

h5.supplier-detail-date {
  font-size: 13px !important;
}

h6.supplier-detail-text {
  font-size: 11px !important;
}

h6.supplier-modal-header {
  font-weight: 900 !important;
  color: #212529 !important;
  font-size: 16px !important;
}

.supplier-info p {
  font-weight: 500 !important;
  color: #212529 !important;
  line-height: 1.5 !important;
}

.quotation-table td {
  padding: 10px 0px !important;
}

/* employee-list page */
.employee-list-input {
  height: 30px !important;
  padding: 0px !important;
}

.employee-signin {
  margin-left: -11px;
}

.float-left.booking-text {
  width: 8%;
}

.booking-text.float-right {
  width: 92%;
}

.float-left.booking-text.one {
  width: 10%;
}

.booking-text.float-right.one {
  width: 90%;
}

.float-left.booking-text.two {
  width: 16%;
}

.booking-text.float-right.two {
  width: 84%;
}

.btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-clean:focus:not(.btn-text),
.btn.btn-clean.focus:not(.btn-text) {
  background-color: transparent !important;
}

.certificate-list-table th {
  font-size: 10px !important;
  font-weight: 400 !important;
}

.certificate-list-table td {
  font-size: 11px !important;
}

h1.headerText {
  font-size: 13px !important;
}

.custome-border {
  position: relative;
}

.custome-border::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 80%;
  background-color: #000000;
  left: 0;
  top: 0;
}

/* booking entry */
.float-left.Rate-commision {
  width: 13%;
  margin-left: 11px;
}

.float-right.Rate-commision {
  width: 85%;
  margin-top: -6px;
}

.float-left.Rate-commision.one {
  width: 7%;
}

.float-right.Rate-commision.one {
  width: 91%;
}

.float-left.Rate-commision.two {
  width: 8%;
}

.float-right.Rate-commision.two {
  width: 90%;
}

input[type="file"] {
  width: 100%;
}

label.form-check-label {
  font-size: 14px;
}

label.form-control.criteriaOptionsInput {
  padding-bottom: 43px;
}

.invoice-price-right-text {
  text-align: right;
  padding: 22px;
  width: 76%;
  font-weight: 900;
  font-size: 16px;
}

@media print {

  #printDiv,
  #printDiv * {
    visibility: visible;
  }

  #printDiv {
    position: absolute;
    left: 0;
    top: 0;
  }
}

th.divisionPos {
  position: absolute;
  width: 15%;
}

th.regionPos {
  position: absolute;
  width: 7%;
  left: 367px;
}

input.fromStyle.form-control {
  height: 10px;
  border: 1px;
}

.refreshIcon {
  font-size: 24px;
}

.balanceleft {
  float: left;
  width: 50%;
  height: 21px;
  font-size: 14px;
}

.markDetails {
  background-color: #8530161f !important;
}

.btn-group.SalesType {
  height: 40px;
}

label.btn.btn-outline-primary.salsRadious {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.profileScreen.card {
  padding: 40px;
}

.aprovedRow span {
  float: left;
  width: 14%;
}

p.aprovedRow {
  position: relative;
}

span.enroll {
  position: absolute;
  right: 30%;
}

span.total {
  position: absolute;
  right: -83px;
}

span.total {
  position: absolute;
  right: -31px;
}

span.quantity {
  position: absolute;
  right: 7%;
}

span.price {
  position: absolute;
  right: 13%;
}

span.soName {
  position: absolute;
  right: 24%;
}

p.aprovedRow {
  position: relative;
  padding: 21px;
  border: 1px solid #ddd;
  background-color: #fffcf5;
}

span.papproved {
  position: absolute;
  left: 40%;
}

span.psoName {
  position: absolute;
  right: 40%;
}

span.pprice {
  position: absolute;
  right: 23%;
}

span.pquantity {
  position: absolute;
  right: 16%;
}

span.papprovedQuntity {
  position: absolute;
  right: 29%;
}

span.pptotal {
  position: absolute;
  right: 8%;
}

.customepadding {
  height: 41px;
}

.dateWiseReport {
  width: 4000px;
}

.tblFooterback {
  background: #f7f7f7 !important;
}

strong.blue {
  font-size: 12px;
  color: #443b3d;
}

.pvtAxisContainer,
.pvtVals {
  border: 1px solid #a2b1c6;
  background: #f2f5fa;
  padding: 5px;
  min-width: 20px;
  min-height: 20px;
  display: none;
}

.pvtRenderers {
  padding-left: 5px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: none;
}

table.pvtTable thead tr th,
table.pvtTable tbody tr th {
  background-color: #fff !important;
  border: 1px solid #c8d4e3 !important;
  font-size: 8pt;
  padding: 5px;
}

.breadBurnPivot {
  width: 200% !important;
}

.achiveLayer span {
  display: block;
  font-size: 14px;
  line-height: 22px;
}

/* td.d1 {
  width: 236px;
} */

/* datewise */
/* th.enroll {
  position: absolute;
  right: 87%;
}
thead.dateThe {
  position: relative;
}
th.product_code {
  position: absolute;
  right: 87%;
}
th.soName {
    position: absolute;
    right: 89%;
}
th.product_name {
  position: absolute;
  right: 83%;
}
th.field0 {
  position: absolute;
  right: 80%;
}

th.field1 {
  position: absolute;
  right: 77%;
}
th.field2 {
  position: absolute;
  right: 74%;
}
th.field3 {
  position: absolute;
  right:71%;
}
th.field4 {
  position: absolute;
  right: 69%;
}
th.field5 {
  position: absolute;
  right: 66%;
}
th.field6 {
  position: absolute;
  right: 63%;
}
th.field7 {
  position: absolute;
  right: 60%;
}
th.field8 {
  position: absolute;
  right: 57%;
}
th.field9 {
  position: absolute;
  right: 54%;
}
th.field10 {
  position: absolute;
  right: 51%;
}
th.field11 {
  position: absolute;
  right: 48%;
}
th.field12 {
  position: absolute;
  right: 45%;
}
th.field13 {
  position: absolute;
  right: 42%;
} */

.validation-symbol {
  color: red !important;
  font-size: 10px !important;
}

.changeLayout {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
}

.bg-yellow {
  background: #FFA800;
  display: block;
  width: 64%;
  margin-right: 13px;
  padding: 6px;
}

.invoiceInfo {
  flex-basis: 8%;
  font-size: 21px;
  font-family: 'Font Awesome 5 Free !important';
}

.bg-yellow2 {
  background: #FFA800;
  flex-basis: 25%;
}

p.invoiceTo {
  font-family: 'Font Awesome 5 Free';
  font-weight: 700 !important;
  font-size: 21px !important;
}

.invoice-detail {
  font-size: 14px;
  font-weight: bold;
  font-family: 'Font Awesome 5 Free';
}

.flbl {
  text-align: right;
}

.fiptdgn {
  height: 75%;
}

.form-group {
  margin-bottom: 0 !important;
}

.btdesign {
  color: #000000;
}

.billcode {
  font-size: 20px;
}

.cdesign {
  height: 80px !important;
  width: 0px !important;
  background: none !important;
}

/* .brand .brand-logo{
  height: 50px;
  width: 50px;
} */
.brand .brand-logo {
  display: flex;

}

.reffcss {
  height: 30px !important;
}
.forimageinput {
  height: 57px !important;
}


.aaaa {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background: #fff;
}

.aaaa:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* .hhh{
  border-right: 3px solid #3699ff ;
} */

.deletecss {
  color: rgb(169, 14, 14)
}

.star {
  color: red;
}

.dropdownZaxis {
  z-index: 100;

}

/* stickyTable */

.stickyTable {
  overflow-x: auto;
  height: 500px;
}

.stickyTable table {
  width: 150%;
  table-layout: fixed;

}

.stickyTable table th {
  top: 0;
  position: sticky;
  background-color: #fff;
}

.shortstickyTable {
  overflow-x: auto;
  height: 500px;
}

.shortstickyTable table {
  width: 100%;
  table-layout: fixed;

}

.shortstickyTable table th {
  top: 0;
  position: sticky;
  background-color: #fff;
}

/* stickyTable */

.rdcustom {
  z-index: 200 !important;
}

input.tadaC {
  width: 85px;
}

ul.uldotremove li {
  padding: 10px;
  border-bottom: 1px solid #f7f7f7;
  cursor: pointer;
  font-weight: 450;
}

.uldotremove {
  list-style: none;
  padding: 0;
  margin: 0;
}
.empname {
  margin-left: 13px;
  background-color: #fff;
  position: absolute;
  z-index: 1000 !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  /* 5px rounded corners */
}
.amount {
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 10px;
  background-color: #1bc5bd;
  overflow: hidden;
  color: #ffff;
  /* padding: 10px; */
  overflow: hidden;

}

.dtqzBx div:first-child {
  white-space: normal !important;
  overflow: initial !important;
  text-overflow: initial !important;
  overflow-wrap: break-word !important;
}

.hbotQl {
  overflow: hidden !important;
  white-space: initial !important;
  text-overflow: ellipsis !important;
}

.borderTableims.table-head-custom thead th,
.borderTableims.table-head-custom thead tr {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border: 1px solid #000 !important;
  padding: 8px;
}

.borderTableims.table-head-custom tbody th,
.borderTableims.table-head-custom tbody tr td {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border: 1px solid #000 !important;
  padding: 8px;
}

.borderTableims.table-head-custom-print thead tr th,
.borderTableims.table-head-custom-print thead tr {
  font-size: 25px;
  border: 1px solid #000 !important;
  padding: 10px;
  height: 20px;
}

.borderTableims.table-head-custom-print tbody th,
.borderTableims.table-head-custom-print tbody tr {
  font-size: 25px;
  border: 1px solid #000 !important;
  padding: 10px;
  height: 20px;
}

.user-list-table td,
th {
  border: 1px solid #ecf0f3 !important;
}

.borderTableims {
  margin-bottom: 2px !important;
}

.borderTableims.table-head-custom thead td {
  color: #464e5f;
  font-weight: 400 !important;
}

.borderTableims.table-head-custom thead th {
  color: #b5b5c3;
  font-weight: 600;
}

.borderTableims.table-head-custom-print thead td {
  color: #000;
  font-size: 25px;
  font-weight: 900 !important;
}

.borderTableims.table-head-custom-print tbody tr td {
  color: #000;
  font-size: 25px;
  border: 1px solid #000 !important;
  font-weight: 900 !important;
}

.table.table-head-custom-print thead th {
  color: #000;
  font-size: 25px;
  font-weight: 900;
}

.namecard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  z-index: 2000 !important;
}
.dbHnaH:not(:last-of-type) {
  border-bottom-width: 0px !important;
}
/* .approvedList .dApqnJ.bQdYbR{
  border: none
} */

.borderTableims {
  margin-bottom: 2px !important;
  }
  
  .borderTableims.table-head-custom thead td {
  color: #000 !important;
  font-weight: 400 !important;
  }
  
  .borderTableims.table-head-custom thead th {
  color: #000 !important;
  font-weight: 600;
  }
  .borderTableims.table-head-custom-print thead td {
  color: #000;
  font-size: 25px;
  font-weight: 900 !important;
  }
  .borderTableims.table-head-custom-print tbody tr td {
  color: #000;
  font-size: 25px;
  border: 1px solid #000 !important;
  font-weight: 900 !important;
  }
  /* daily basis */
  .dailybasistable.table-head-custom thead th,
.dailybasistable.table-head-custom thead tr {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border: 1px solid #000 !important;
  padding: 8px;
 
}

.dailybasistable.table-head-custom tbody th,
.dailybasistable.table-head-custom tbody tr td {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border: 1px solid #000 !important;
  padding: 8px;
 
}

.dailybasistable.table-head-custom-print thead tr th,
.dailybasistable.table-head-custom-print thead tr {
  font-size: 25px;
  border: 1px solid #000 !important;
  padding: 10px;
  height: 20px;
  
}

.dailybasistable.table-head-custom-print tbody th,
.dailybasistable.table-head-custom-print tbody tr {
  font-size: 25px;
  border: 1px solid #000 !important;
  padding: 10px;
  height: 20px;
  
}

.dailybasistable {
  margin-bottom: 2px !important;
}

.dailybasistable.table-head-custom thead td {
  color: #000;
}

.dailybasistable.table-head-custom thead th {
  color: #000 !important;
  font-weight: 600;
  
}

.dailybasistable.table-head-custom-print thead td {
  color: #000;
  font-size: 25px;
  font-weight: 900 !important;
}

.dailybasistable.table-head-custom-print tbody tr td {
  color: #000;
  font-size: 25px;
  border: 1px solid #000 !important;
  font-weight: 900 !important;

}
  /* daily basis */
  .datecus{
    align-items: center !important;
    justify-content: center !important;
  }
  .table tbody + tbody {
    border-top: none;
}
.tadabill{
  text-align: center;
  justify-content: center;
  font-size: medium;
  font-weight: 500;
  text-decoration: underline;
}
.tadabilltext p{
  font-size: 14px;
  font-weight: 500;
}
.signature{
  font-weight: 600;
}
.trcus {
  color: #000;
  border: 1px solid #000 !important;
  font-weight: 900 !important;
}

.empnamezaxis{
z-index: 1000;
}
.asideHeading{
  color: #FFF;
}

.dailybasis{
  background-color: #a0d9ec;
}
.hIWEKa {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 0 !important;
  color: rgba(0,0,0,0.87);
  font-size: 12px;
  font-weight: 500;
}
